@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
} */

@layer base {
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
